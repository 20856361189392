<template>

  <div class="mt-1">

    <section class="mybets-title py-2">
      <div class="row">
        <div class="col-7 text-right p-0">
            <span class="bet-history-title text-yellow">
                My Bets History
            </span>
        </div>
        <div class="col-5 p-0 px-1">
            <span class="small-text text-blue">
                Click the bet to view details
            </span>
        </div>
      </div>
    </section>

    <section class="bets-menu d-flex text-blue justify-content-left">

      <div class="w-20 p-1 px-2 tablinks" v-bind:class="isActive('normal_bets')" @click="setActive('normal_bets')">
        Normal
      </div>

      <div class="w-20 p-1 px-2 tablinks" v-bind:class="isActive('jackpot_bets')" @click="setActive('jackpot_bets')">
        Jackpot
      </div>

      <div class="w-20 p-1 px-2 tablinks" v-bind:class="isActive('free_bets')" @click="setActive('free_bets')">
        Bonus
      </div>

      <div class="w-20 p-1 px-2 tablinks" v-bind:class="isActive('busta_bets')" @click="setActive('busta_bets')">
        CashAfriq
      </div>

      <div class="w-20 p-1 px-2 tablinks" v-bind:class="isActive('cashout_bets')" @click="setActive('cashout_bets')">
        Cashout
      </div>

    </section>

    <div v-show="active_menu === 'normal_bets'">
      <div v-for="(b,index) in normal_bets" v-bind:key="getKey(index)">
      <section class="tabcontent" style="display: block">

        <hr class="mb-1 mt-1">
        <div class="row m-0 p-0 bet-sec hover-pointer mb-1" data-toggle="collapse" v-bind:data-target="getDataTarget(b.id)" aria-expanded="false" v-bind:aria-controls="getArialControl(b.id)">
          <div class="col-3 line-height-0 px-1">
            <span class="text-yellow">
                BET ID:<span class="text-light">#{{ b.code}}</span>  <br>
                <span class="text-light"> {{ formatMatchDate(b.created) }}</span>
            </span>
          </div>
          <div class="col-2 line-height-0 px-1">
            <span class="text-yellow">
                Stake   <br>
                <span class="text-light">{{ b.stake | currency }}</span>
            </span>
          </div>
          <div class="col-3 line-height-0 px-1">
            <span class="text-yellow">
                Pos. Win  <br>
                <span class="text-light"> {{ b.possible_win | currency }}</span>
            </span>
          </div>
          <div class="col-2 line-height-0 px-1">
            <span class="text-yellow">
                Status  <br>

                <!--<span class="text-success bet-icon" v-if="b.statusName == 'Won'"><i class="bi bi-check-circle-fill"></i></span>-->
                <span class="text-light">{{ b.statusName }}</span>
            </span>
          </div>
          <div class="col-2 line-height-0 px-1">
              <span  class="text-yellow" style="">
                  <!--Cashout-->  <br>
                  <div  class="col-12 text-center slip-col px-1" v-if="canCashout(b.id)" >
                <div  style="display: none" v-show="parseInt(b.filter_status) === 0"  @click="requestCashout(b.id)" class="small-text text-center ">Cashout</div>
              </div>
              </span>
          </div>
        </div>

        <div class="collapse collapseMybet" :id="getArialControl(b.id)">

          <div class="row m-0 p-0 bet-sec mb-1">
            
            <div class="col-4 line-height-0 px-1">
          <span class="text-yellow">
              Matches won  <br>
              <span class="text-light"> {{ b.won_games }} out of {{ b.bets }}</span>
          </span>
            </div>
            <div class="col-4  text-center slip-col px-1">
              <a v-if="b.statusName=='Pending'" href="" class="login-button py-1 form-control small-text" data-toggle="collapse" :data-target="'#collapseShareSlip'+b.code" aria-expanded="false" aria-controls="collapseShareSlip">Share Betslip</a>

            </div>
            <div class="col-4  text-center slip-col px-1">
              <a href="" class="login-button py-1 form-control  small-text " data-toggle="collapse" v-bind:data-target="getDataTarget(b.id)" aria-expanded="false" v-bind:aria-controls="getArialControl(b.id)">Close [x]</a>
            </div>
          </div>

          <!--  Buttons section  -->
          <div class="row m-0 p-0 mb-2 d-none">

            

            <!--<div class="col-4 small-btn-padding text-center slip-col px-1" v-bind:class="cashoutLoading">
              <div style="display: none" v-show="parseInt(b.filter_status) === 0" @click="requestCashout(b.id)" class="login-button py-1 form-control small-text ">Cashout</div>
            </div>-->

            

          </div>

          <section class="shareSlipSection collapse" :id="'collapseShareSlip'+b.code">
            <hr class="m-1">
            <div class="text-yellow text-center matches-title mb-2">
              Share Betslip
            </div>
            <div class="social-icons justify-content-around px-2 text-center mb-2 d-flex">
              <a href="https://www.facebook.com/JetpesaKE/"
                   target="_blank" title="Share on Facebook"
                   @click="facebookShare(b.code)">
                <img src="/img/facebook.png"> <br>
                <span class="text-blue small-text"> Facebook</span>
              </a>
              <a href="https://instagram.com/jetpesa?utm_medium=copy_link&t="
                   title="Share on Facebook"
                   @click="instagramShare(b.code)"
                   target="_blank">
                <img src="/img/instagram.png"><br>
                <span class="text-blue small-text"> Instagram</span>
              </a>
              <a data-action="share/whatsapp/share" target="_blank"
                   @click="whatsappShare(b.code)">
                <img src="/img/whatsapp.png"><br>
                <span class="text-blue small-text"> Whatsapp</span>
              </a>
              <a href="https://twitter.com/jetpesa?s=11"
                   target="_blank" title="Tweet"
                   @click="twitterShare(b.code)">
                <img src="/img/twitter.png"><br>
                <span class="text-blue small-text"> Twitter</span>
              </a>
              <a data-action="share/telegram/share" target="_blank"
                   @click="telegramShare(b.code)">
                <img src="/img/telegram.png"><br>
                <span class="text-blue small-text"> Telegram</span>
              </a>
            </div>

            <div class="row p-0 m-0 px-2 mb-2">
              <div class="col-7 px-1">
                <input v-bind:value="getShareUrl(b.code)" class="share-betslip-btn">
              </div>
              <div class="col-5 px-1">
                <button class="share-btn"  @click="copyShareBetLink(b.code)">Copy Link</button>
              </div>
            </div>

            <div class="text-center slip-ttxt py-3 d-none">
              <div class="text-blue mb-3">You can also download a copy of your betslip in pdf</div>
              <div>
                <a href="" class="login-button py-2 px-4 ">Download betslip</a>
              </div>
            </div>
            <hr class="m-1 d-none">

            <div class="text-center slip-ttxt py-3 d-none">
              <div class="text-blue mb-3">Want to print out the betslip?</div>
              <div>
                <a href="" class="login-button py-2 px-4 ">Print betslip</a>
              </div>
            </div>
          </section>
          <hr class="m-0">
          <div class="text-primary-dark text-center matches-title bet-content-section">
            Matches
          </div>

          <div v-for="(p,index) in b.picked" v-bind:key="index" >
            <div class="content-section bet-content-section px-1">

              <div class="row m-0 p-0">

                <div class="col-7 m-0 p-0">
                  <span class="text-dark small-text">{{ p.competition }}</span>
                  <span class="text-dark small-text">{{ formatMatchDate(p.match_date) }}</span>
                  

                </div>
                <div class="col-3 m-0 p-0">
                  <span class="text-primary-dark"> -
                      <span v-show="parseInt(p.producer_id) === 3" class="text-primary-dark">Pre-match</span>
                      <span v-show="parseInt(p.producer_id) === 1" class="text-primary-dark">Live-match</span>
                  </span>
                </div>

                <!-- <div class="col-2">
                  <span class="text-light small-text">Results</span>
                </div> -->

                <div class="col-0 d-none pick text-dark small-text">
                  <div class="pick text-dark small-text p-1 text-center">
                    Pick <span class="text-dark">{{ p.outcome_name }}</span>
                  </div>
                </div>

                <div class="col-2 m-0 p-0 pick text-dark small-text">
                  <div class="pick text-dark small-text p-1 text-center">
                    Status
                  </div>
                  
                </div>

              </div>
              <div class="row m-0 p-0">
                <div class="col-6 m-0 p-0">
                  <div class="teams text-dark">
                    {{ getHomeCompetitorName(p.match_name) }} V {{ getAwayCompetitorName(p.match_name) }}
                  </div>
                  <div class="small-text text-dark" style="opacity: .75">
                    {{ p.market_name }} - <span class="">Pick: </span>{{ p.outcome_name }}
                  </div>

                </div>
                <div class="col-1 m-0 p-0">
                  <span v-if="p.fixture_status">
                    <span class="text-dark" v-if="parseInt(p.status) === 0 ">- : -</span>
                    <span class="small-text text-dark" v-else>{{ p.fixture_status.home_score }}-{{ p.fixture_status.away_score }}</span>
                  </span>
                </div>
                <div class="col-3 m-0 p-0 text-center">
                  <span class="odds-bts text-primary-dark">{{ p.odd | formatOdds }}</span>
                </div>
                <div class="col-2 m-0 p-0 text-center">
                  <span class="text-success bet-icon" v-if="getStatusName(p) == 'Won'"><i class="bi bi-check-circle-fill"></i></span>
                  <span class="text-danger bet-icon" v-else-if="getStatusName(p) == 'Lost'"><i class="bi bi-x-circle-fill"></i></span>

                  
                  <span class="text-dark" v-else>{{ getStatusName(p) }}</span>
                  
                </div>
              </div>
            </div>
            <hr class="m-0">
          </div>

        </div>

      </section>
      </div>
    </div>

    <div v-show="active_menu === 'jackpot_bets'">
      <div v-for="(b,index) in jackpot_bets" v-bind:key="getKey(index)">

        <section class="tabcontent" style="display: block">

          <hr class="mb-1 mt-1">
          <div class="row m-0 p-0 bet-sec hover-pointer mb-1" data-toggle="collapse" v-bind:data-target="getDataTarget(b.id)" aria-expanded="false" v-bind:aria-controls="getArialControl(b.id)">
            <div class="col-3 line-height-0 px-1">
              <span class="text-yellow">
                  BET ID:<span class="text-light">#{{ b.code}}</span>  <br>
                  <span class="text-light"> {{ formatMatchDate(b.created) }}</span>
              </span>
            </div>
            <div class="col-3 line-height-0 px-1">
              <span class="text-yellow">
                  Stake Amount  <br>
                  <span class="text-light"> Ksh.{{ b.stake | currency }}</span>
              </span>
            </div>
            <div class="col-4 line-height-0 px-1">
              <span class="text-yellow">
                  Possible Win  <br>
                  <span class="text-light"> Ksh.{{ b.possible_win | currency }}</span>
              </span>
            </div>
            <div class="col-2 line-height-0 px-1">
              <span class="text-yellow">
                  Status  <br>
                  <span class="text-light">{{ b.statusName }}</span>
              </span>
            </div>
          </div>

          <div class="collapse collapseMybet" :id="getArialControl(b.id)">
          <div class="row m-0 p-0 bet-sec mb-1">
            
            <div class="col-4 line-height-0 px-1">
          <span class="text-yellow">
              Matches won  <br>
              <span class="text-light"> {{ b.won_games }} out of {{ b.bets }}</span>
          </span>
            </div>
            <div class="col-4 line-height-0 px-1">
              <div class="col-8 small-btn-padding text-center slip-col px-1">
              <a href="" class="login-button py-1 form-control small-text d-none " data-toggle="collapse" data-target="#collapseShareSlip" aria-expanded="false" aria-controls="collapseShareSlip">Share Betslip</a>
            </div>
            </div>
            <div class="col-4  text-center slip-col px-1">
              <a href="" class="login-button py-1 form-control  small-text " data-toggle="collapse" v-bind:data-target="getDataTarget(b.id)" aria-expanded="false" v-bind:aria-controls="getArialControl(b.id)">Close [x]</a>
            </div>
          </div>
            <div class="row m-0 p-0 bet-sec mb-1 d-none">
              <div class="col-3 line-height-0 px-1"></div>
              <div class="col-4 line-height-0 px-1">
              <span class="text-yellow">
                Matches won  <br>
                <span class="text-light"> {{ b.won_games }} out of {{ b.bets }}</span>
              </span>
              </div>
            </div>
            <!--  Buttons section  -->
            <div class="row m-0 p-0 mb-2 d-none">

              <div class="col-4 small-btn-padding text-center slip-col px-1">
                <a href="" class="login-button py-1 form-control small-text d-none " data-toggle="collapse" data-target="#collapseShareSlip" aria-expanded="false" aria-controls="collapseShareSlip">Share Betslip</a>
              </div>

              <div class="col-4 small-btn-padding text-center slip-col px-1 d-none" v-bind:class="cashoutLoading">
                <div style="display: none" v-show="parseInt(b.filter_status) === 0" @click="requestCashout(b.id)" class="login-button py-1 form-control small-text ">Cashout</div>
              </div>

              <div class="col-4 small-btn-padding text-center slip-col px-1">
                <a href="" class="login-button py-1 form-control  small-text " data-toggle="collapse" v-bind:data-target="getDataTarget(b.id)" aria-expanded="false" v-bind:aria-controls="getArialControl(b.id)">Close [x]</a>
              </div>
            </div>

            <section class="shareSlipSection collapse" id="collapseShareSlip">
              <hr class="m-1">
              <div class="text-yellow text-center matches-title mb-2">
                Share Betslip
              </div>
              <div class="social-icons d-flex justify-content-around px-2 text-center mb-2">
                <a>
                  <img src="/img/facebook.png"> <br>
                  <span class="text-blue small-text"> Facebook</span>
                </a>
                <a>
                  <img src="/img/instagram.png"><br>
                  <span class="text-blue small-text"> Instagram</span>
                </a>
                <a>
                  <img src="/img/whatsapp.png"><br>
                  <span class="text-blue small-text"> Whatsapp</span>
                </a>
                <a>
                  <img src="/img/twitter.png"><br>
                  <span class="text-blue small-text"> Twitter</span>
                </a>
                <a>
                  <img src="/img/telegram.png"><br>
                  <span class="text-blue small-text"> Telegram</span>
                </a>
              </div>

              <div class="row p-0 m-0 px-2 mb-2">
                <div class="col-7 px-1">
                  <input value="https://jetpesa.co.ke" class="share-betslip-btn">
                </div>
                <div class="col-5 px-1">
                  <button class="share-btn">Copy Link</button>
                </div>
              </div>

              <hr class="m-1">

              <div class="text-center slip-ttxt py-3">
                <div class="text-blue mb-3">You can also download a copy of your betslip in pdf</div>
                <div>
                  <a href="" class="login-button py-2 px-4 ">Download betslip</a>
                </div>
              </div>
              <hr class="m-1">

              <div class="text-center slip-ttxt py-3">
                <div class="text-blue mb-3">Want to print out the betslip?</div>
                <div>
                  <a href="" class="login-button py-2 px-4 ">Print betslip</a>
                </div>
              </div>
            </section>
            <hr class="m-1">
            <div class="text-primary-dark text-center matches-title bet-content-section">
              Matches
            </div>
            <div v-for="(p,index) in b.picked" v-bind:key="index">
              <div class="content-section bet-content-section px-1">
                <div class="row m-0 p-0">

                  <div class="col-7 m-0 p-0 pr-1" style="overflow-x: auto; opacity:.8">
                    <span class="text-dark small-text">{{ p.competition }}</span>
                    
                  </div>
                  <div class="col-2 m-0 p-0 opacity-8">
                    <span class="text-dark small-text">Results</span>
                  </div>
                  <div class=" col-0 pick text-light small-text">
                    <div class="pick text-dark small-text p-0 text-center opacity-8">
                      <span class="text-dark small-text">{{ formatMatchDate(p.match_date) }}</span>
                    </div>
                  </div>

                </div>
                <div class="row m-0 p-0">
                  <div class="col-7 m-0 p-0">
                    <div class="teams text-dark">
                      {{ getHomeCompetitorName(p.match_name) }} V {{ getAwayCompetitorName(p.match_name) }}
                    </div>
                    <div class="small-text text-dark">
                      {{ p.market_name }} - <span class="">Pick: </span>{{ p.outcome_name }}
                    </div>
                  </div>
                  <div class="col-2 m-0 p-0">
                  <span v-if="p.fixture_status">
                    <span v-if="parseInt(p.status) === 0 ">- : -</span>
                    <span class="small-text text-dark" v-else>{{ p.fixture_status.home_score }}-{{ p.fixture_status.away_score }}</span>
                  </span>
                  </div>
                  <div class="col-3 m-0 p-0 text-center">
                    <span class="odds-bts text-dark">{{ p.odd | formatOdds }}</span>
                  </div>
                </div>
              </div>
              <hr class="m-0">
            </div>

          </div>

        </section>
      </div>
    </div>

    <div v-show="active_menu === 'free_bets'">
      <div v-for="(b,index) in free_bets" v-bind:key="getKey(index)">

        <section class="tabcontent" style="display: block">

          <hr class="mb-1 mt-1">
          <div class="row m-0 p-0 bet-sec hover-pointer mb-1" data-toggle="collapse" v-bind:data-target="getDataTarget(b.id)" aria-expanded="false" v-bind:aria-controls="getArialControl(b.id)">
            <div class="col-3 line-height-0 px-1">
          <span class="text-yellow">
              BET ID:<span class="text-light">#{{ b.code}}</span>  <br>
              <span class="text-light"> {{ formatMatchDate(b.created) }}</span>
          </span>
            </div>
            <div class="col-3 line-height-0 px-1">
          <span class="text-yellow">
              Stake Amount  <br>
              <span class="text-light"> {{ b.stake | currency }}</span>
          </span>
            </div>
            <div class="col-3 line-height-0 px-1">
              <span class="text-yellow">
                  Possible Win  <br>
                  <span class="text-light"> {{ b.possible_win | currency }}</span>
              </span>
            </div>
            <div class="col-3 line-height-0 px-1">
          <span class="text-yellow">
              Status  <br>
              <span class="text-light">{{ b.statusName }}</span>
          </span>
            </div>
          </div>

          <div class="collapse collapseMybet" :id="getArialControl(b.id)">
          <div class="row m-0 p-0 bet-sec mb-1">
            
            <div class="col-4 line-height-0 px-1">
          <span class="text-yellow">
              Matches won  <br>
              <span class="text-light"> {{ b.won_games }} out of {{ b.bets }}</span>
          </span>
            </div>
            <div class="col-4 line-height-0 px-1">
              <div class="col-8 small-btn-padding text-center slip-col px-1">
              <a href="" class="login-button py-1 form-control small-text d-none " data-toggle="collapse" data-target="#collapseShareSlip" aria-expanded="false" aria-controls="collapseShareSlip">Share Betslip</a>
            </div>
            </div>
            <div class="col-4  text-center slip-col px-1">
              <a href="" class="login-button py-1 form-control  small-text " data-toggle="collapse" v-bind:data-target="getDataTarget(b.id)" aria-expanded="false" v-bind:aria-controls="getArialControl(b.id)">Close [x]</a>
            </div>
          </div>
            <div class="row m-0 p-0 bet-sec mb-1 d-none">
              <div class="col-3 line-height-0 px-1"></div>
              <div class="col-4 line-height-0 px-1">
              <span class="text-yellow">
                Matches won  <br>
                <span class="text-light"> {{ b.won_games }} out of {{ b.bets }}</span>
              </span>
              </div>
            </div>
            <!--  Buttons section  -->
            <div class="row m-0 p-0 mb-2 d-none">

              <div class="col-4 small-btn-padding text-center slip-col px-1">
                <a href="" class="login-button py-1 form-control small-text d-none " data-toggle="collapse" data-target="#collapseShareSlip" aria-expanded="false" aria-controls="collapseShareSlip">Share Betslip</a>
              </div>

              <div class="col-4 small-btn-padding text-center slip-col px-1" >
                <a href="" class="login-button py-1 disabled form-control small-text d-none ">Cashout</a>
              </div>

              <div class="col-4 small-btn-padding text-center slip-col px-1">
                <a href="" class="login-button py-1 form-control  small-text " data-toggle="collapse" data-target="#collapseMybet" aria-expanded="false" aria-controls="collapseMybet">Close [x]</a>
              </div>
            </div>

            <section class="shareSlipSection collapse" id="collapseShareSlip">
              <hr class="m-1">
              <div class="text-yellow text-center matches-title mb-2">
                Share Betslip
              </div>
              <div class="social-icons d-flex justify-content-around px-2 text-center mb-2">
                <a>
                  <img src="/img/facebook.png"> <br>
                  <span class="text-blue small-text"> Facebook</span>
                </a>
                <a>
                  <img src="/img/instagram.png"><br>
                  <span class="text-blue small-text"> Instagram</span>
                </a>
                <a>
                  <img src="/img/whatsapp.png"><br>
                  <span class="text-blue small-text"> Whatsapp</span>
                </a>
                <a>
                  <img src="/img/twitter.png"><br>
                  <span class="text-blue small-text"> Twitter</span>
                </a>
                <a>
                  <img src="/img/telegram.png"><br>
                  <span class="text-blue small-text"> Telegram</span>
                </a>
              </div>

              <div class="row p-0 m-0 px-2 mb-2">
                <div class="col-7 px-1">
                  <input value="https://jetpesa.co.ke" class="share-betslip-btn">
                </div>
                <div class="col-5 px-1">
                  <button class="share-btn">Copy Link</button>
                </div>
              </div>

              <hr class="m-1">

              <div class="text-center slip-ttxt py-3">
                <div class="text-blue mb-3">You can also download a copy of your betslip in pdf</div>
                <div>
                  <a href="" class="login-button py-2 px-4 ">Download betslip</a>
                </div>
              </div>
              <hr class="m-1">

              <div class="text-center slip-ttxt py-3">
                <div class="text-blue mb-3">Want to print out the betslip?</div>
                <div>
                  <a href="" class="login-button py-2 px-4 ">Print betslip</a>
                </div>
              </div>
            </section>
            <hr class="m-1">
            <div class="text-primary-dark text-center matches-title bet-content-section">
            Matches
          </div>

          <div v-for="(p,index) in b.picked" v-bind:key="index" >
            <div class="content-section bet-content-section px-1">

              <div class="row m-0 p-0">

                <div class="col-7 m-0 p-0">
                  <span class="text-dark small-text">{{ p.competition }}</span>
                  <span class="text-dark small-text">{{ formatMatchDate(p.match_date) }}</span>
                  

                </div>
                <div class="col-3 m-0 p-0">
                  <span class="text-primary-dark"> -
                      <span v-show="parseInt(p.producer_id) === 3" class="text-primary-dark">Pre-match</span>
                      <span v-show="parseInt(p.producer_id) === 1" class="text-primary-dark">Live-match</span>
                  </span>
                </div>

                <!-- <div class="col-2">
                  <span class="text-light small-text">Results</span>
                </div> -->

                <div class="col-0 d-none pick text-dark small-text">
                  <div class="pick text-dark small-text p-1 text-center">
                    Pick <span class="text-dark">{{ p.outcome_name }}</span>
                  </div>
                </div>

                <div class="col-2 m-0 p-0 pick text-dark small-text">
                  <div class="pick text-dark small-text p-1 text-center">
                    Status
                  </div>
                  
                </div>

              </div>
              <div class="row m-0 p-0">
                <div class="col-6 m-0 p-0">
                  <div class="teams text-dark">
                    {{ getHomeCompetitorName(p.match_name) }} V {{ getAwayCompetitorName(p.match_name) }}
                  </div>
                  <div class="small-text text-dark" style="opacity: .75">
                    {{ p.market_name }} - <span class="">Pick: </span>{{ p.outcome_name }}
                  </div>

                </div>
                <div class="col-1 m-0 p-0">
                  <span v-if="p.fixture_status">
                    <span class="text-dark" v-if="parseInt(p.status) === 0 ">- : -</span>
                    <span class="small-text text-dark" v-else>{{ p.fixture_status.home_score }}-{{ p.fixture_status.away_score }}</span>
                  </span>
                </div>
                <div class="col-3 m-0 p-0 text-center">
                  <span class="odds-bts text-primary-dark">{{ p.odd | formatOdds }}</span>
                </div>
                <div class="col-2 m-0 p-0 text-center">
                  <span class="text-success bet-icon" v-if="getStatusName(p) == 'Won'"><i class="bi bi-check-circle-fill"></i></span>
                  <span class="text-danger bet-icon" v-else-if="getStatusName(p) == 'Lost'"><i class="bi bi-x-circle-fill"></i></span>

                  
                  <span class="text-dark" v-else>{{ getStatusName(p) }}</span>
                  
                </div>
              </div>
            </div>
            <hr class="m-0">
          </div>


          </div>

        </section>
      </div>
    </div>

    <div v-show="active_menu === 'busta_bets'">
      <div v-for="(b,index) in bustabets" v-bind:key="getKey(index)">
        <section class="tabcontent"  style="display: block">
            <hr class="mb-1 mt-1">
            <div class="row m-0 p-0 bet-sec hover-pointer mb-1" data-toggle="collapse" v-bind:data-target="b.game_id" aria-expanded="false" v-bind:aria-controls="getArialControl(b.game_id)">

              <div class="col-4 line-height-0 px-1">
                <span class="text-yellow">
                    Game ID:<br><span class="text-light">#{{ b.game_id }} </span>  <br> <span class="text-light"> {{ formatMatchDate(b.created) }}</span>
                </span>
              </div>

              <div class="col-3 line-height-0 px-1">
                  <span class="text-yellow">
                      Stake Amount  <br> <span class="text-light"> Ksh. {{ formatCurrency(b.stake) }}</span>
                  </span>
              </div>

              <div class="col-3 line-height-0 px-1">
                  <span class="text-yellow">
                      Possible Win  <br> <span class="text-light"> Ksh.{{ formatCurrency(b.net_winnings) }}</span>
                  </span>
              </div>

              <div class="col-2 line-height-0 px-1">
                  <span class="text-yellow">
                      Status  <br> <span class="text-light">{{ b.status }}</span>
                  </span>
              </div>

            </div>
        </section>
      </div>
    </div>

    <div v-show="active_menu === 'cashout_bets'">

      <div v-for="(b,index) in cashout_bets" v-bind:key="getKey(index)">

        <section class="tabcontent" style="display: block">

          <hr class="mb-1 mt-1">
          <div class="row m-0 p-0 bet-sec hover-pointer mb-1" data-toggle="collapse" v-bind:data-target="getDataTarget(b.id)" aria-expanded="false" v-bind:aria-controls="getArialControl(b.id)">

            <div class="col-3 line-height-0 px-1">
              <span class="text-yellow">
                  BET ID:<span class="text-light">#{{ b.code}}</span>  <br>
                  <span class="text-light"> {{ formatMatchDate(b.created) }}</span>
              </span>
            </div>

            <div class="col-3 line-height-0 px-1">
              <span class="text-yellow">
                  Stake Amount  <br>
                  <span class="text-light"> Ksh.{{ b.stake | currency }}</span>
              </span>
            </div>
            <div class="col-3 line-height-0 px-1">
              <span class="text-yellow">
                  Possible Win  <br>
                  <span class="text-light"> Ksh.{{ b.possible_win | currency }}</span>
              </span>
            </div>
            <div class="col-3 line-height-0 px-1">
              <span class="text-yellow">
                  Status  <br>
                  <span class="text-light">{{ b.statusName }}</span>
              </span>
            </div>
          </div>

          <div class="collapse collapseMybet" :id="getArialControl(b.id)">

            <div class="row m-0 p-0 bet-sec mb-1">
              <div class="col-3 line-height-0 px-1">
              </div>
              <div class="col-4 line-height-0 px-1">
                <span class="text-yellow">
                    Matches won  <br>
                    <span class="text-light"> {{ b.won_games }} out of {{ b.bets }}</span>
                </span>
              </div>
            </div>

            <!--  Buttons section  -->
            <div class="row m-0 p-0 mb-2">

              <div class="col-4 small-btn-padding text-center slip-col px-1">
                <a href="" class="login-button py-1 form-control small-text d-none " data-toggle="collapse" data-target="#collapseShareSlip" aria-expanded="false" aria-controls="collapseShareSlip">Share Betslip</a>
              </div>

              <div class="col-4 small-btn-padding text-center slip-col px-1">
                <a href="" class="login-button py-1 form-control  small-text " data-toggle="collapse" v-bind:data-target="getDataTarget(b.id)" aria-expanded="false" v-bind:aria-controls="getArialControl(b.id)">Close [x]</a>
              </div>

            </div>

            <hr class="m-0">
            <div class="text-primary-dark text-center matches-title bet-content-section">
              Matches
            </div>

            <div v-for="(p,index) in b.picked" v-bind:key="index">
              <div class="content-section px-1 d-none">

                <div class="row">

                  <div class="col-5">
                    <span class="text-blue small-text">{{ p.competition }}</span>
                    <span class="text-light small-text">{{ formatMatchDate(p.match_date) }}</span>
                    <span class="text-yellow"> -
                      <span v-show="parseInt(p.producer_id) === 3" class="text-yellow">Pre-match</span>
                      <span v-show="parseInt(p.producer_id) === 1" class="text-yellow">Live-match</span>
                  </span>

                  </div>

                  <div class="col-2">
                    <span class="text-light small-text">Results</span>
                  </div>

                  <div class="col-3 pick text-light small-text">
                    <div class="pick text-light small-text p-1 text-center">
                      Pick <span class="text-blue">{{ p.outcome_name }}</span>
                    </div>
                  </div>

                  <div class="col-2 pick text-light small-text">
                    <div class="pick text-light small-text p-1 text-center">
                      Status
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-5">
                    <div class="teams text-light">
                      {{ getHomeCompetitorName(p.match_name) }} V {{ getAwayCompetitorName(p.match_name) }}
                    </div>
                    <div class="small-text text-blue">
                      {{ p.market_name }}
                    </div>
                  </div>
                  <div class="col-2">
                  <span v-if="p.fixture_status">
                    <span v-if="parseInt(p.status) === 0 ">- : -</span>
                    <span class="small-text text-blue" v-else>{{ p.fixture_status.home_score }}-{{ p.fixture_status.away_score }}</span>
                  </span>
                  </div>
                  <div class="col-3 text-center">
                    <span class="odds-bts">{{ p.odd | formatOdds }}</span>
                  </div>
                  <div class="col-2 text-center">
                    <span class="text-success bet-icon" v-if="getStatusName(p) == 'Won'"><i class="bi bi-check-circle-fill"></i></span>
                    <span class="text-light" v-else>{{
                        (p) }}</span>
                  </div>
                </div>
              </div>
              <div class="content-section bet-content-section px-1">

              <div class="row m-0 p-0">

                <div class="col-7 m-0 p-0">
                  <span class="text-dark small-text">{{ p.competition }}</span>
                  <span class="text-dark small-text">{{ formatMatchDate(p.match_date) }}</span>
                </div>

                <div class="col-3 m-0 p-0">
                  <span class="text-primary-dark"> -
                      <span v-show="parseInt(p.producer_id) === 3" class="text-primary-dark">Pre-match</span>
                      <span v-show="parseInt(p.producer_id) === 1" class="text-primary-dark">Live-match</span>
                  </span>
                </div>

                <!-- <div class="col-2">
                  <span class="text-light small-text">Results</span>
                </div> -->

                <div class="col-0 d-none pick text-dark small-text">
                  <div class="pick text-dark small-text p-1 text-center">
                    Pick <span class="text-dark">{{ p.outcome_name }}</span>
                  </div>
                </div>

                <div class="col-2 m-0 p-0 pick text-dark small-text">
                  <div class="pick text-dark small-text p-1 text-center">
                    Status
                  </div>
                  
                </div>

              </div>
              <div class="row m-0 p-0">
                <div class="col-6 m-0 p-0">
                  <div class="teams text-dark">
                    {{ getHomeCompetitorName(p.match_name) }} V {{ getAwayCompetitorName(p.match_name) }}
                  </div>
                  <div class="small-text text-dark" style="opacity: .75">
                    {{ p.market_name }} - <span class="">Pick: </span>{{ p.outcome_name }}
                  </div>

                </div>
                <div class="col-1 m-0 p-0">
                  <span v-if="p.fixture_status">
                    <span class="text-dark" v-if="parseInt(p.status) === 0 ">- : -</span>
                    <span class="small-text text-dark" v-else>{{ p.fixture_status.home_score }}-{{ p.fixture_status.away_score }}</span>
                  </span>
                </div>
                <div class="col-3 m-0 p-0 text-center">
                  <span class="odds-bts text-primary-dark">{{ p.odd | formatOdds }}</span>
                </div>
                <div class="col-2 m-0 p-0 text-center">
                  <span class="text-success bet-icon" v-if="getStatusName(p) == 'Won'"><i class="bi bi-check-circle-fill"></i></span>
                  <span class="text-danger bet-icon" v-else-if="getStatusName(p) == 'Lost'"><i class="bi bi-x-circle-fill"></i></span>

                  
                  <span class="text-dark" v-else>{{ getStatusName(p) }}</span>
                  
                </div>
              </div>
            </div>
              <hr class="m-0">
            </div>

          </div>

        </section>
      </div>

    </div>

  </div>

</template>

<script>


import axios from "@/services/api";

export default {
  name: 'Bets',
  components: {

  },
  data: function () {
    return {
      amount: 0,
      loading: '',
      bet: {},
      bets:[],
      cashout_can:[],
      bustabets:[],
      view_more: false,
      filter_status:  0, // 0 - all, 1 - active , 2 - won , 3 - lost , 4 - cancelled,
      all: 0,
      allbusta: 0,
      pending: 0,
      won: 0,
      lost: 0,
      voided: 0,
      cancelled: 0,
      className: '',
      collapseMybet: 'collapseMybet',
      normal_bets: [],
      jackpot_bets: [],
      free_bets: [],
      cashout_bets:[],
      active_menu: 'normal_bets',
      cashoutLoading: '',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function() {
        // react to route changes...
        document.title = "My Bets";
        document.description = "Manage Bets and Results";
      }
    },
  },
  mounted() {
    this.$store.dispatch("setCurrentPage","bets");
    this.myBets();
    this.bustaBets();
    this.reloadProfile();
    this.setFilter(-10)
  },
  methods: {
    facebookShare:function(code){
      window.open('https://www.facebook.com/JetpesaKE/' + encodeURIComponent('https://jetpesa.co.ke/share/'+code)); return false;
    },
    instagramShare:function(code){
      window.open('https://instagram.com/jetpesa?utm_medium=copy_link' + encodeURIComponent('https://jetpesa.co.ke/share/') + code + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;
    },
    whatsappShare:function(code){
      window.open('whatsapp://send?text=' + encodeURIComponent('https://jetpesa.co.ke/share/'+code) ); return false;
    },
    twitterShare:function(code){
      window.open('https://twitter.com/jetpesa?s=11text=' + encodeURIComponent('https://jetpesa.co.ke/share/'+code)); return false;
    },
    telegramShare: function(code){
      window.open('https://telegram.me/share/url?url=' + encodeURIComponent('https://jetpesa.co.ke/share/'+code) + '&text=' + encodeURIComponent('https://jetpesa.co.ke/share/'+code)); return false;
    },
    getShareUrl: function(code){
      return "https://jetpesa.co.ke/share/"+code
    },
    copyShareBetLink: function (code) {

      var link = "https://jetpesa.co.ke/share/"+code;
      this.copyToClipboard(link);
      this.copyText = 'Copied';
      this.setSuccess("Success", "Sharebet link copied");


    },
    canCashout: function(id){
      // console.log(id)
     
      // console.log(id)
      // console.log(this.cashout_can.indexOf(id));
      if(this.cashout_can.indexOf(id) == -1){
        return true;
      }else{
        return false;
      }
    },
    getStatusName: function (picked){

      if(parseInt(picked.status) === 0 ) {

        return "Pending";

      }

      if(parseInt(picked.status) === 1 ) {

        return "Pending";

      }

      if(parseInt(picked.status) === -1) {

        return "Cancelled";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {

        return "Won";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 0)  {

        return "Lost";

      }

      if(parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {

        return "Cashout";

      }

      return '';

    },

    getDataTarget: function(id){

      return "#collapseMybet"+id;
    },
    getArialControl: function(id){

      return "collapseMybet"+id;
    },

    isActive: function(menu){

      return this.active_menu === menu ? 'active' : '';
    },
    setActive: function(menu){

      this.active_menu = menu;
    },
    getBetFilterStatus: function (bet){

      switch (parseInt(bet.status)) {

        case 0:
          return 'Pending';

        case 1:
          return 'Lost';

        case 2:
          return 'Won';

        case 3:
          return 'Voided';

        case 4:
          return 'Cancelled';
      }
    },

    shareBet: function(code,odds){

      var vm = this;
      var ob = {
        code: code,
        odds: odds
      };

      console.log(JSON.stringify(ob));
      vm.EventBus.$emit('share:bet',ob);

    },
    getCounterClass: function(status){

      if(parseInt(status) === parseInt(this.filter_status)) {

        return 'bets-counter-selected';
      }

      return 'bets-counter-unselected';

    },
    getHomeCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }

      return matchName.split('vs.')[0];
    },
    getAwayCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }
      return matchName.split('vs.')[1];
    },
    getMatchClass: function (picked) {

      if(parseInt(picked.status) === 0 ) {

        return "bd-callout-pending";

      }

      if(parseInt(picked.status) === 1 ) {

        return "bd-callout-pending";

      }

      if(parseInt(picked.status) === -1) {

        return "bd-callout-cancel";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {

        return "bd-callout-won";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 0)  {

        return "bd-callout-lost";

      }
    },
    viewAll: function () {

      this.view_more = false;

    },
    setFilter: function (filter_status) {

      this.filter_status = filter_status

    },
    getMyBetLabelClass: function (bet){

      switch (parseInt(bet.filter_status)) {

        case 0:
          return 'bet-text-pending';

        case 1:
          return 'bet-text-lost';

        case 2:
          return 'bet-text-won';

        case 3:
          return 'bet-text-voided';

        case 4:
          return 'bet-text-cancelled';
      }

      return '';

    },
    getBetsBorderClass: function (bet){

      switch (parseInt(bet.filter_status)) {

        case 0:
          return 'bets-item-pending';

        case 1:
          return 'bets-item-lost';

        case 2:
          return 'bets-item-won';

        case 3:
          return 'bets-item-voided';

        case 4:
          return 'bets-item-cancelled';
      }

      return '';

    },

    myBets: function () {

      this.reset();
      var p = this.getProfile();
      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_BETS.replace("{profile_id}", p.d);

      var data = {};

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.message;
            vm.bets = msg;

            vm.all = vm.bets.length;

            vm.jQuery.each(vm.bets,function(k,v){

              var filter_status = parseInt(v.processing_status);
              v.filter_status = filter_status;
              v.statusName = vm.getBetStatusName(v);
              v.betLabelClass = vm.getMyBetLabelClass(v);
              v.borderClass = vm.getBetsBorderClass(v);

              switch (parseInt(v.processing_status)) {

                case 0:
                  vm.pending++
                  break

                case 1:
                  vm.lost++
                  break

                case 2:
                  vm.won++
                  break

                case 3:
                  vm.voided++
                  break

                case 4:
                  vm.cancelled++
                  break

                case 5:
                  vm.cashout++
                  break
              }

              if(parseInt(v.processing_status) === 5 ){

                vm.cashout_bets.push(v);

              } else if(parseInt(v.bet_type) === 4 ) {

                vm.jackpot_bets.push(v);

              } else if(parseInt(v.bet_type) === 1 || parseInt(v.bet_type) === 2 ) {

                vm.free_bets.push(v);

              } else {
                // console.log(v);
                vm.normal_bets.push(v);

              }

            });

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;
              } else {

                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));
              }

            }
            else if (err.request) {

              console.log(JSON.stringify(err.request));

            }
            else {

              console.log(JSON.stringify(err));

            }

          })
    },

    bustaBets: function () {

      this.reset();
      var p = this.getProfile();

      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_BUSTAPESA.replace("{profile_id}", p.d);

      var data = {};

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.message;
            vm.bustabets = msg;
            // console.log(JSON.stringify(vm.bustabets));
            vm.allbusta = vm.bustabets.length;

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;
              } else {

                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));
              }

            }
            else if (err.request) {

              console.log(JSON.stringify(err.request));

            }
            else {

              console.log(JSON.stringify(err));

            }

          })
    },
    selectedClass: function (filter_status) {

      return this.filter_status === filter_status ? 'bets-tab-text btn-selected': 'bets-tab-text btn-normal';

    },
    viewBet: function(bet) {

      this.view_more = true;

      if(typeof bet.fixture_status === undefined || bet.fixture_status === undefined ) {

        bet.fixture_status = {};
        bet.fixture_status.home_score = '-';
        bet.fixture_status.away_score = '-';
      }

      this.bet = bet;

    },
    getBorderClass: function (picked){

      if(parseInt(picked.status) === 0 ) {

        return "bets-item-pending";

      }

      if(parseInt(picked.status) === 1 ) {

        return "bets-item-pending";

      }

      if(parseInt(picked.status) === -1) {

        return "bets-item-cancel";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {

        return "bets-item-won";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 0)  {

        return "bets-item-lost";

      }

      return '';

    },
    getBetStatusName: function (bet){

      switch (parseInt(bet.filter_status)) {

        case 0:
          return 'Pending';

        case 1:
          return 'Lost';

        case 2:
          return 'Won';

        case 3:
          return 'Voided';

        case 4:
          return 'Cancelled';

        case 5:
          return 'Cashout';
      }

      return '';

    },
    getBetLabelClass: function (picked){

      if(parseInt(picked.status) === 0 ) {

        return "bet-text-pending";

      }

      if(parseInt(picked.status) === 1 ) {

        return "bet-text-pending";

      }

      if(parseInt(picked.status) === -1) {

        return "bet-text-cancel";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {

        return "bet-text-won";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 0)  {

        return "bet-text-lost";

      }

      return '';

    },
    openCity: function (cityName) {

      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");

      for (i = 0; i < tabcontent.length; i++) {

        tabcontent[i].style.display = "none";
      }

      tablinks = document.getElementsByClassName("tablinks");

      for (i = 0; i < tablinks.length; i++) {

        tablinks[i].className = tablinks[i].className.replace(" active", "");

      }

      if(document.getElementById(cityName)) {

        document.getElementById(cityName).style.display = "block";

      }

      this.className += " active";
    },
    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'unique-id-' + index + '-');

    },
    requestCashout: function (bet_id) {
    // var element = document.getElementById('bet'+bet_id);
    // element.classList.add("d-none");
    //   element.style.display = "none"  
    // console.log("trying to close __")
    
      this.reset();
      var p = this.getProfile();
      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_REQUEST_CASHOUT.replace("{profile_id}", p.d);
      var data = {
        bet_id: bet_id
      };

      vm.cashoutLoading = 'loading';
      
      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {
            
            //var msg = res;
           
            var amount = res.data.message.amount;
            var net_amount = amount - (0.2*amount);
            //var description = res.data.message.description;
            // console.log('GOT cashout amount here '+JSON.stringify(amount))

            //text: "Cashout this bet at Ksh. "+vm.formatCurrency(amount),

            vm.$swal.fire({
              title: "Cashout this bet at Ksh. "+vm.formatCurrency(net_amount),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Cashout'

            }).then((result) => {

              if (result.isConfirmed) {

                var path = process.env.VUE_APP_URL_ACCEPT_CASHOUT.replace("{profile_id}", p.d);

                var data = {
                  bet_id: bet_id
                };

                axios.post(path, JSON.stringify(data),{
                  headers: {
                    'api-key': vm.getAuth()
                  },
                })
                    .then(res => {

                      vm.cashoutLoading = '';

                      console.log("RESPONSE ==> "+JSON.stringify(res))
                     this.cashout_can.push(bet_id);
                      vm.$swal.fire(
                          'Submitted!',
                          'Your cashout is being processed, you will be notified shortly',
                          'success'
                      )
                    })
                    .catch(err => {

                      vm.cashoutLoading = '';

                      vm.$swal.fire(
                          'Failed!',
                          'Error occurred processing your cashout request',
                          'error'
                      )

                      if (err.response) {

                        var message = "";

                        if(parseInt(err.response.status) === 428 ) {

                          message = err.response.data.message;
                          vm.setError("Failed", message)
                          return;
                        }

                        if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                          vm.setError("Session Expired", "Your session on this device has expired");
                          vm.logout();
                          return;

                        } else {

                          message =  err.response.data.message;
                          vm.setError("Failed", message)

                        }

                      } else if (err.request) {

                        vm.setError("Failed", "Please check your network")
                        console.log(JSON.stringify(err.request));

                      } else {

                        //vm.setError("Failed", "Please check your network")
                        console.log(JSON.stringify(err));

                      }
                    })
              } else {

                vm.cashoutLoading = '';

              }

            })

          })
          .catch(err => {

            vm.cashoutLoading = '';

            console.log("RESPONSE HERE "+JSON.stringify(err));
            vm.$swal.fire(
                'Failed!',
                'Cashout for your bet is not available at the moment',
                'error'
            )

          })
    },

  },
  computed: {
    profile: function() {
      return this.getProfile();
    },
    filteredBets: function () {
      var vm = this;

      if(vm.filter_status === -10){

        return this.bets;
      }

      var bets = [];
      this.jQuery.each(this.bets,function(k,v){

        if(parseInt(v.processing_status) === parseInt(vm.filter_status)) {

          bets.push(v)
        }
      });

      return bets;
    },
    borderClass: function (){

      switch (parseInt(this.filter_status)) {

        case 0:
          return 'bets-item-pending';

        case 1:
          return 'bets-item-lost';

        case 2:
          return 'bets-item-won';

        case 3:
          return 'bets-item-voided';

        case 4:
          return 'bets-item-cancelled';
      }

      return '';

    },
    statusName: function (){

      switch (parseInt(this.filter_status)) {

        case 0:
          return 'Pending';

        case 1:
          return 'Lost';

        case 2:
          return 'Won';

        case 3:
          return 'Voided';

        case 4:
          return 'Cancelled';
      }

      return '';

    },
    betLabelClass: function (){

      switch (parseInt(this.filter_status)) {

        case 0:
          return 'bet-text-pending';

        case 1:
          return 'bet-text-lost';

        case 2:
          return 'bet-text-won';

        case 3:
          return 'bet-text-voided';

        case 4:
          return 'bet-text-cancelled';
      }

      return '';

    },
  },
  filters: {
    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
}
</script>
