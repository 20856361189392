<template>

  <div class="wrapper">

    <section class="register-form-wrapper p-4" v-show="action_login">
      <h3 class="text-light text-center mt-1 mb-3"> <span class="text-yellow">Login</span> </h3>
      <div class="text-center text-light mb-3">
        Enter your phone number and Password below
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="input-group mb-0">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input type="tel" class="form-control" id="phoneInput" placeholder="Phone number 07 or 01" aria-label="Enter Phone number" name="mobile" v-model="msisdn" >
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
            
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center">Invalid phone number</small>
          <div class="mb-3"></div>
          <div class="input-group mb-0">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input :type="type"  class="form-control" placeholder="Enter Password" aria-label="Enter Password" name="password" id="inputPass" v-model="password">
            <div class="input-group-append">
              <span class="input-group-text"><img :src="btnText" class="input-group-text-icon" @click="showPassword"></span>
            </div>
          </div>
          <small class="text-blue mb-3 d-none">Enter the 4 digit code sent to your phone</small>
          <input type="hidden" name="utm_source" value="">
          <input type="hidden" name="utm_medium" value="">
          <input type="hidden" name="utm_campaign" value="">
          <input type="hidden" name="referrer" value="">
        </form>
      </div>

      <div class="disclaimer text-center d-none">
        By continuing for Jetpesa, you confirm that you are 18 years old or over and agree with the <a class="text-yellow">Terms, conditions and policies</a>  of Jetpesa
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="login" class="join-button py-2 form-control">Login</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Did you forget your Password? <br>
          No worries!
        </div>
        <a @click="setGetResetCode" class="login-button py-2 form-control">Reset</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-light mb-3">
          Don't have an account? <br>
          Easy! Join Jetpesa <br>
        </div>
        <a @click="setSignup" class="login-button py-2 form-control">Join Now</a>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_signup">
      <h3 class="text-light text-center mt-1 mb-4">Join <span class="text-yellow">Jetpesa</span> </h3>
      <div class="form-wrapper mb-3">
        <form>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input type="tel" class="form-control" placeholder="Phone number 07 or 01" aria-label="Enter Phone Number" name="mobile" id="msisdn" v-model="msisdn">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input type="password" class="form-control" placeholder="Password" aria-label="Enter Password" name="password" id="signupPassword" v-model="password">
            <!-- <div class="input-group-append">
              <span class="input-group-text"><img :src="btnText" class="input-group-text-icon"></span>
            </div> -->
          </div>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input type="password" class="form-control" placeholder="Confirm Password" aria-label="Confirm password" name="password_2" id="signupPassword1" v-model="password1">
            <!-- <div class="input-group-append">
              <span class="input-group-text"><img :src="btnText" class="input-group-text-icon"></span>
            </div> -->
          </div>
        </form>
      </div>

      <div class="disclaimer text-center">
        By registering for Jetpesa, you confirm that you are 18 years old or over and agree with the <a class="text-yellow">Terms, conditions and policies</a>  of Jetpesa
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="signup" class="join-button py-2 form-control">Get registration code</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Already have an account? <br>
          Welcome back <br>
        </div>
        <a @click="setLogin" class="login-button py-2 form-control">Login</a>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_verify_password">
      <h3 class="text-light text-center mt-1 mb-3">Registration <span class="text-yellow">Code</span> </h3>
      <div class="text-center text-light mb-3">
        Check your phone for an SMS verification code
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <!--<div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Phone number 07 or 01" aria-label="Amount (to the nearest dollar)">
            <div class="input-group-append">
              <span class="input-group-text"></span>
            </div>
          </div>-->
          <div class="input-group mb-0">
            <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div>
            <input type="tel" class="form-control" placeholder="Enter code" id="code" aria-label="Enter Code" v-model="code">
            <!--<div class="input-group-append">
              <span class="input-group-text"><img src="/img/other/eye.svg" class="input-group-text-icon"></span>
            </div>-->
          </div>
          <small class="text-blue mb-3">Enter the 4 digit code sent to your phone</small>

        </form>
      </div>

      <div class="disclaimer text-center">
        By activating yout Jetpesa account, you confirm that you are 18 years old or over and agree with the <a class="text-yellow">Terms, conditions and policies</a>  of Jetpesa
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="verifyAccount" class="join-button py-2 form-control">Activate Account</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Didn’t get an SMS code?<br>
          Let’s try again in<br>
          (15 seconds)
        </div>
        <a href="#n" class="login-button py-2 form-control">Resend</a>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_reset_password">
      <h3 class="text-light text-center mt-1 mb-3">Reset your <span class="text-yellow">Password</span> </h3>
      <div class="text-center text-light mb-3">
        Enter your phone number
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input type="tel" class="form-control" id="msisdn1" placeholder="Phone number 07 or 01" aria-label="Amount (to the nearest dollar)" v-model="msisdn">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>

        </form>
      </div>

      <!--<div class="disclaimer text-center d-none">
        By cont for Jetpesas, you confirm that you are 18 years old or over and agree with the <a class="text-yellow">Terms, conditions and policies</a>  of Jetpesa
      </div>-->

      <div class="button-wrapper text-center mb-3">
        <a @click="getResetCode" class="join-button py-2 form-control">Get Reset Code</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Already have a reset code? <br>
          Proceed to verification<br>
        </div>
        <a href="#" class="login-button py-2 form-control">Enter SMS Code</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-light mb-3">
          Already have an account? <br>
          Welcome back<br>
        </div>
        <a @click="setLogin" class="login-button py-2 form-control">Login</a>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_change_password">
      <h3 class="text-light text-center mt-1 mb-3">Reset your <span class="text-yellow">Password</span> </h3>
      <div class="text-center text-light mb-3">
        Password reset code has been send to your mobile number. Enter the code you have received in your phone and your new password.
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input type="tel" class="form-control" placeholder="Enter code" id="code1" aria-label="Enter Code" v-model="code">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input type="password" class="form-control" placeholder="New Password" aria-label="New Password" name="password" id="signupPassword3" v-model="password">
            <div class="input-group-append">
              <span class="input-group-text"><img :src="btnText2" class="input-group-text-icon" @click="showPasswordReset"></span>
            </div>
          </div>
          <div class="input-group mb-0">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input type="password" class="form-control" placeholder="Confirm New Password" aria-label="Confirm New Password" name="password_2" id="signupPassword4" v-model="password1">
            <div class="input-group-append">
              <span class="input-group-text"><img :src="btnText3" class="input-group-text-icon" @click="showPasswordReset2"></span>
            </div>
          </div>


        </form>
      </div>

      <div class="disclaimer text-center d-none">
        By cont for Jetpesa, you confirm that you are 18 years old or over and agree with the <a class="text-yellow">Terms, conditions and policies</a>  of Jetpesa
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="changePassword" class="join-button py-2 form-control">Change Password</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Already have a reset code? <br>
          Proceed to verification<br>
        </div>
        <a href="#" class="login-button py-2 form-control">Enter SMS Code</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-light mb-3">
          Already have an account? <br>
          Welcome back<br>
        </div>
        <a @click="setLogin" class="login-button py-2 form-control">Login</a>
      </div>

    </section>


  </div>

  

</template>

<style scoped>
.input-signup, .input-login{
  border: 1px solid #ccc;
  border-radius: .5em;
  height: 3em;
}

</style>

<script>
import axios from "@/services/api";
export default {
  name: 'Login',
  components: {
  },
  comments: {

  },
  data: function () {

    return {
      msisdn: '',
      password: '',
      password1: '',
      error: [],
      warning: [],
      success: [],
      loading: '',
      action_login: true,
      action_signup: false,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: '',
      placeBet: 0,
      type: 'password',
      type2: 'password',
      type3: 'password',
      btnText: '/img/other/eye.svg',
      btnText2: '/img/other/eye.svg',
      btnText3: '/img/other/eye.svg'
    }
  },
  computed: {
    phoneInvalid: function(){
      if(this.msisdn.length < 10 && this.msisdn.length > 0){
        return true;
      }else{
        return false;
      }
      
    }
  },
  methods: {
    login: function () {

      this.reset();
      this.removeAuth();

      if (this.msisdn.length < 10) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag",login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var profile = res.data.message;
            var status = res.data.status;

            if(parseInt(status) === 201 ) {

              // take password verification code
              vm.setVerifyAccount();
              return;

            }

            vm.setProfile(profile);

            var auth = profile.a;
            vm.setAuth(auth);
            vm.setCasinoToken(profile.t1)

            vm.setSuccess("Success", "Login successful");
            vm.$router.push({ name: 'home' });
            // go to previous page
            console.log('Inititalize MQTT');
            vm.EventBus.$emit('init:mqtt');

            if (parseInt(vm.placeBet) === 1 ) {
              this.$store.dispatch("setPlaceBet", 1).then(() => {
                vm.goBack();
              });
              
              
              // vm.EventBus.$emit('event:betslip:show');
            } else if (parseInt(vm.placeBet) === 2 ) {
              vm.setValue("placeBet",0);
              vm.EventBus.$emit('event:outrightbetslip:show');
            } else {
              vm.goBack();
            }

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed",err.response.data.message)

              if(parseInt(err.response.status) === 428 ) {

                vm.setVerifyAccount();
                return;
              }
            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })

    },
    handleBlur: function (){
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        document.getElementById('phoneInput').classList.add("is-invalid")

      }else{
        document.getElementById('phoneInput').classList.remove("is-invalid")
      }
    },
    signup: function () {

      this.reset();

      if (this.msisdn.length < 9) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_SIGNUP;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");
      var referral_code = this.getValue("referral_code");

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
        src: referral_code
      }))
          .then(res => {

            vm.loading = '';
            console.log(JSON.stringify(res));
            var msg = res.data.message;
            vm.setSuccess("Signup successful", msg);
            // go to password verification page
            vm.setVerifyAccount();

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })

    },
    getResetCode: function () {

      this.reset();

      if (this.msisdn.length < 9) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn)
      }))
          .then(res => {

            vm.loading = '';
            console.log(JSON.stringify(res));

            vm.setSuccess("Success", "Password reset code has been send to your phone");

            // go to password reset page
            vm.setChangePassword();

          })
          .catch(err => {

            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })

    },
    changePassword:function () {

      this.reset();

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_PASSWORD_CHANGE;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        old_password: this.code
      }))
          .then(res => {

            console.log(JSON.stringify(res));

            vm.loading = '';
            vm.setSuccess("Password Changed", "Your password has been changed successfully,. Please login to continue");

            // go to login page
            vm.setLogin();

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })

    },
    verifyAccount: function () {

      this.reset();

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag",login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        code: this.code,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var profile = res.data.message;
            vm.setProfile(profile);
            var auth = profile.a;
            vm.setAuth(auth);

            vm.setSuccess("Congratulations!!", "Your account has been verified successfully");
            vm.EventBus.$emit('init:mqtt');

            if (parseInt(vm.placeBet) === 1 ) {

              vm.setValue("placeBet",0);
              vm.EventBus.$emit('event:betslip:show');

            } else {

              vm.goHome();

            }

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })

    },
    setSignup: function(){

      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setLogin: function(){

      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setGetResetCode: function(){

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;

    },
    setChangePassword: function(){

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setVerifyAccount: function(){

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;

    },
    showPassword: function () {
       if(document.getElementById('inputPass').type == "password"){
        document.getElementById('inputPass').type = "text"
        document.getElementById('inputPass').style.color = "white"
      }else{
        document.getElementById('inputPass').type = "password"
        document.getElementById('inputPass').style.color = "rgba(255,255,255,.75)"
      }
      // if(document.getElementById('signupPassword1').type == "password"){
      //   document.getElementById('signupPassword1').type = "text"
      // }else{
      //   document.getElementById('signupPassword1').type = "password"
      // }
      if(this.type === 'password') {
        this.type = 'text'
        this.btnText = '/img/other/eyeslash.svg'
      } else {
        this.type = 'password'
        this.btnText = '/img/other/eye.svg'
      }
    },

    showPasswordReset: function () {
       if(document.getElementById('signupPassword3').type == "password"){
        document.getElementById('signupPassword3').type = "text"
        document.getElementById('signupPassword3').style.color = "white"
      }else{
        document.getElementById('signupPassword3').type = "password"
        document.getElementById('signupPassword3').style.color = "rgba(255,255,255,.75)"
      }
      if(this.type2 === 'password') {
        this.type2 = 'text'
        this.btnText2 = '/img/other/eyeslash.svg'
      } else {
        this.type2 = 'password'
        this.btnText2 = '/img/other/eye.svg'
      }
    },
    showPasswordReset2: function () {
       if(document.getElementById('signupPassword4').type == "password"){
        document.getElementById('signupPassword4').type = "text"
        document.getElementById('signupPassword4').style.color = "white"
      }else{
        document.getElementById('signupPassword4').type = "password"
        document.getElementById('signupPassword4').style.color = "rgba(255,255,255,.75)"
      }
      if(this.type3 === 'password') {
        this.type3 = 'text'
        this.btnText3 = '/img/other/eyeslash.svg'
      } else {
        this.type3 = 'password'
        this.btnText3 = '/img/other/eye.svg'
      }
    }
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","login");
    this.placeBet = this.getValue("placeBet");
    var p = this.getProfile();
      if(!p) {
        console.log("");
      }else{
        this.$router.push({ name: 'home', params: { } });
        return;
      }

  }
}
</script>