<template>

  <div>

    <section class="account-details px-0 py-0">

      <div class="row px-1 py-1 m-0 mb-2">

        <div class="col-6">
          <div class="line-height-0">
            <span class="player-profile-details text-light">Player profile details</span><br>
            <strong class="text-light">{{  profile.f3 }} {{  profile.f1 }} {{ profile.f2 }}</strong><br>
            <span class="small-text text-light">Date joined: {{ formatMatchDate(profile.dj) }} </span>
          </div>
        </div>

        <div class="col-6">
          <div class="account ">
            <span class="text-blue">Account Balance</span><br>
            <strong class="green-text">Ksh. <span v-text="bal"></span></strong>
          </div>
          <router-link to="/deposit" class="deposit-link text-light">
            <button class="depo-btn">Deposit</button>
          </router-link>
        </div>

      </div>
      <div class="row px-1 py-1 m-0">
        <div class="col-6">
          <div class="line-height-0">
            <span class="player-profile-details text-blue">Account Number</span><br>
            <strong class="text-light">{{ getUIValue(profile.m) }}</strong><br>
          </div>
        </div>
        <div class="col-6">
          <div class="account">
            <span class="text-blue">Bonus</span><br>
            <strong class="text-blue">Ksh. {{ formatCurrency(profile.b4 + profile.b2) }}</strong>
          </div>
        </div>
      </div>

    </section>

    <hr class="m-1">
    <div class="text-yellow text-center matches-title mb-1">
      Withdraw <br>
      <span class="text-blue">Withdraw funds from your Jetpesa account</span>
    </div>
    <section class="px-2 mb-3">
      <div class="form-wrapper mb-0">
        <div class="input-group mb-0">
          <div class="input-group-prepend">
            <span class="input-group-text text-blue">Ksh</span>
          </div>
          <input type="text" class="form-control" name="amount" placeholder="100" aria-label="Amount (to the nearest dollar)" v-model="withdraw_amount">
          <div class="input-group-append">
            <span class="input-group-text"></span>
          </div>
        </div>
        <small class="text-blue mb-3 mt-3">Minimum Ksh. 100 Maximum Ksh. 70,000</small>
        <button @click="withdraw" v-bind:class="loading" class="join-button py-2 form-control" style="color: black !important;">WITHDRAW</button>
      </div>
    </section>

    <hr class="m-1">

    <div class="text-yellow text-center matches-title mb-1">
      Refer A friend and Earn <br>
      <span class="text-blue">Many gifts awaiting for you if you share the below link with your friends</span>
    </div>

    <section class="px-2 mb-3">

      <div class="social-icons d-flex justify-content-around px-2 text-center mb-2">

        <a href="https://www.facebook.com/JetpesaKE/"
           target="_blank" title="Tweet"
           onclick="window.open('https://www.facebook.com/JetpesaKE/' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
          <img src="/img/facebook.png"><br>
          <span class="text-blue small-text"> Facebook</span>
        </a>

        <a href="https://instagram.com/jetpesa?utm_medium=copy_link&t="
           title="Share on Facebook"
           onclick="window.open('https://instagram.com/jetpesa?utm_medium=copy_link' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
           target="_blank">
          <img src="/img/instagram.png"> <br>
          <span class="text-blue small-text"> Instagram</span>
        </a>

        <a data-action="share/whatsapp/share" target="_blank"
           onclick="window.open('whatsapp://send?text=' + document.getElementById('share-bet-msg').textContent); return false;">
          <img src="/img/whatsapp.png"><br>
          <span class="text-blue small-text"> Whatsapp</span>
        </a>

        <a href="https://twitter.com/JetpesaKe?s=11"
           target="_blank" title="Tweet"
           onclick="window.open('https://twitter.com/jetpesa?s=11text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
          <img src="/img/twitter.png"><br>
          <span class="text-blue small-text"> Twitter</span>
        </a>

        <a data-action="share/telegram/share" target="_blank"
           onclick="window.open('https://telegram.me/share/url?url=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
          <img src="/img/telegram.png"><br>
          <span class="text-blue small-text"> Telegram</span>
        </a>

      </div>

      <div class="row p-0 m-0 px-2 mb-2">
        <div class="col-7 px-1">
          <div id="share-bet-link" class="share-betslip-btn" style="color: #FFFFFF">{{ link }}</div>
          <div class="d-none" id="share-bet-code">{{ code }}</div>
          <div class="d-none" id="share-bet-msg">{{ msg }}</div>
        </div>
        <div class="col-5 px-1">
          <button class="share-btn" @click="copyShareBetLink">{{ copyText }}</button>
        </div>
      </div>

    </section>


  </div>


</template>

<script>

import axios from "@/services/api";

export default {

  name: 'Setting',
  components: {

  },
  data: function () {
    return {
      amount: 0,
      loading: '',
      withdraw_amount: "",
      new_referral_code: '',
      errors: [],
      code: '',
      link:'',
      msg: '',
      copyText: 'Copy',
      copyBookingCode: 'Click to copy',
      myProfile: this.getProfile(),
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // react to route changes...
        document.title = "My Jetpesa Account";
        document.description = "Manage Jetpesa account";
      }
    },
    new_referral_code: function(n){

      console.log('got new value here '+n);

      this.validateReferralLink();
    }
  },
  mounted() {

    var vm = this;

    this.$store.dispatch("setCurrentPage","my-account");
    vm.myProfile = vm.getProfile();

    if(!vm.myProfile) {

      this.setError("Login","Please login to proceed");
      this.$router.push({ name: 'login', params: { } });
      return;
    }

    // setInterval(function () {

    //   vm.myProfile = vm.getProfile();

    // },2000);

    this.code = vm.myProfile.referral_code;
    this.link = vm.myProfile.referral_link;
    this.msg =   "Hey!! Nabet na Jetpesa. Join me to be a champion, click this link to signup >> \n\n\n\n "+this.link;

  },
  methods: {

    setAmount: function (amount) {

      this.amount = amount
    },
    deposit: function () {

      this.reset();
      var p = this.getProfile();
      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      if(this.amount < 1 ) {

        this.setError("Invalid Amount","ENter amount atleast 1 KSH or above");
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msgs = res.data.message;
            vm.setSuccess("Deposit Initiated",msgs);

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;
              } else {

                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));

              }

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })
    },
    createReferralLink: function () {

      this.reset();
      this.errors = [];

      var p = this.getProfile();
      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      if(this.new_referral_code.length < 4 ) {

        this.errors.push('Referral code should be more than 3 characters');

      }

      if(this.new_referral_code.length > 20 ) {

        this.errors.push('Referral code should be less than 21 characters');

      }

      if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {

        this.errors.push('Referral code can only contain letters A-Z and numbers 0 - 9');

      }

      if(this.errors.length > 0 ) {

        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_URL_GENERATE_REFERRAL_LINK.replace("{profile_id}", p.d);

      var data = {

        code: this.new_referral_code
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.link = res.data.message.profile.referral_link
            vm.code = res.data.message.profile.referral_code
            var prof = res.data.message.profile
            var msgs = res.data.message.description

            if(prof) {

              vm.setProfile(prof);
            }

            vm.setSuccess("Link Generated",msgs);

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                vm.setError("Failed", err.response.data.message)
                vm.errors.push(err.response.data.message);

              }

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })
    },
    validateReferralLink: function () {

      this.errors = [];

      if(this.new_referral_code.length < 4 ) {
        this.errors.push('Referral code should be more than 3 characters');
      }

      if(this.new_referral_code.length > 20 ) {
        this.errors.push('Referral code should be less than 21 characters');
      }

      if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {
        this.errors.push('Referral code can only contain letters A-Z and numbers 0 - 9');
      }

    },
    withdraw: function () {

      this.reset();
      var p = this.getProfile();
      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      if(this.withdraw_amount < 100 ) {

        this.setError("Invalid Amount","Enter amount atleast 100 KSH or above");
        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_URL_WITHDRAW.replace("{profile_id}", p.d);

      var data = {
        amount: parseInt(this.withdraw_amount)
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msgs = res.data.message;
            vm.setSuccess("Withdrawal Initiated",msgs);

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));
              }

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })
    },
    shareInvite: function(){

      document.getElementById("open-shareinvite").click();

    },
    copyShareBetLink: function () {

      var link = this.link;
      this.copyToClipboard(link);
      this.copyText = 'Copied';

    },
    copyCode: function () {

      var link = "accept#"+this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = 'Invite Code Copied';

    },

  },
  computed: {

    bal: function() {

      return this.formatCurrency(this.profile.b1);

    },
    profile: function() {

      return this.myProfile;
    },
    has_referral_code: function (){

      return this.code !== undefined && this.code.length > 3;

    },

  },
  filters: {
    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
}
</script>